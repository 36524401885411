import request from '@/utils/request'

const api = {
  getContractPage: '/adxSettle/contractPage',
  updateContractSts: '/adxSettle/updateContractSts'
}

export default api

export function getContractPage (data) {
  return request({
    url: api.getContractPage,
    method: 'post',
    data
  })
}

export function updateContractSts (data) {
  return request({
    url: api.updateContractSts,
    method: 'post',
    data
  })
}
