<template>
  <div class="contract-settle-container">
    <div class="contract-settle-search">
      <div class="search-box">
        <a-range-picker
          style="width: 240px"
          class="search_item"
          format="YYYY-MM-DD"
          :placeholder="['开始日期', '结束日期']"
          @change="ChangeDate"
          :value="rangePickerDate"
          :allowClear="true"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :disabledDate="disabledDate"
        >
        </a-range-picker>
        <m-select v-model="query.contractSts" :showSearch="false" class="search_item" :dataList="[ {name:'是', id: 1}, {name: '否', id: 0}]" label="合同状态"/>
        <a-input placeholder="请输入公司名称或者账号名称" style="width: 240px" v-model.trim="query.name" />
      </div>
      <div >
        <a-button class="btn" type="primary" @click="handleSearch">查询</a-button>
      </div>
    </div>
    <div class="contract-settle-table">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData">
          <el-table-column prop="partnerName" label="部署平台" width="120"> </el-table-column>
          <el-table-column prop="registerTime" label="注册时间" width="200"> </el-table-column>
          <el-table-column prop="company" label="公司名称" width="180"> </el-table-column>
          <el-table-column prop="email" label="登录账号" width="180"> </el-table-column>
          <el-table-column prop="contact" label="联系人"> </el-table-column>
          <el-table-column prop="mobile" label="联系方式"> </el-table-column>
          <el-table-column prop="appNum" label="关联应用数"> </el-table-column>
          <el-table-column prop="contractSts" label="合同状态" width="100">
            <template slot-scope="{ row }">
              <a-switch @change="changeContactSts($event, row)" size="small" :checked="+row.contractSts === 1" />
            </template>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description>暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-else
          show-size-changer
          :current="query.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="query.limit"
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getListForManage'
import { getDuration } from '@/utils/duration'
import { getContractPage, updateContractSts } from '@/apiForManage/adSettle'

export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      query: {
        dateEnd: '',
        dateStart: '',
        name: undefined,
        contractSts: undefined,
        page: 1,
        limit: 10
      },
      total: 0,
      isLoading: false,
      tableData: []
    }
  },
  created () {
    // 初始化时间
    // const { endDate, startDate, end, start } = this.initDate(7, 1)
    // this.rangePickerDate = [start, end]
    // this.query.dateEnd = endDate
    // this.query.dateStart = startDate
    this.getTableList()
  },
  methods: {
    changeContactSts (e, { trafficUserId }) {
      updateContractSts({
        trafficUserId,
        contractSts: +e
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success('状态修改成功')
          this.getTableList()
        }
      })
    },
    handleSearch () {
      this.query.page = 1
      this.getTableList()
    },
    ChangeDate (date, dateString) {
      this.query = {
        ...this.query,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    async getTableList () {
      this.isLoading = true
      const { data = {} } = await getContractPage(this.query)
      const { items = [], total = 0 } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    },
    handleChangePage (page) {
      this.query.page = page
      this.getTableList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableList()
    }
  }
}
</script>

<style lang="less" scoped>
.contract-settle-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .contract-settle-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .search_item {
        width: 180px;
        margin-right: @compMargin;
      }
    }
    .btn {
      width: 88px;
    }
  }
  .contract-settle-table {
    padding: 0 @smallMargin;
    border-radius: @mediumRadius;
    background-color: #fff;
    margin-top: @mediumMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    padding-top: calc(@smallMargin - @thPadding);
    .pagination {
      padding: @smallMargin 0;
    }
  }
}
</style>
